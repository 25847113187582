<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red"
          :dark="!events || events.length > 0"
          style="margin-right: 10px;"
          v-on="on"
          v-bin="attrs"
          @click.stop.prevent="action = 'delete'"
          :disabled="!events || events.length === 0"
        >
        <v-icon>mdi-checkbox-multiple-marked</v-icon> <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="#CCCCCC"
          @click="action = 'visibility'"
          :disabled="!events || events.length === 0"
        >
          <v-icon>mdi-checkbox-multiple-marked</v-icon> <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <v-card>
        <!--v-card-title class="text-h5">
          {{action}}
        </v-card-title-->
        <v-card-text>
          <v-container fluid>
            <v-btn 
              v-if="events.length > 0"
              @click="handleSelectAll"
              text
              color="primary"
              block
            >
              seleccionar todos
            </v-btn>
            <v-checkbox
              v-for="event of events"
              :key="event.id"
              v-model="selectedEvents"
              :label="prepareEventName(event)"
              :value="event.id"
              multiple
              hide-details
              :color="event.color"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="handleClose"
          >
            {{ $t('common.cancel', locale) }}
          </v-btn>
          <v-btn
            v-if="action === 'delete'"
            color="red"
            dark
            @click="handleDelete"
          >
            {{ $t('common.delete', locale) }}
          </v-btn>
          <v-btn
            v-if="action === 'visibility'"
            color="#CCCCCC"
            @click="handleHideShow(true)"
          >
            {{ $t('common.hide', locale) }}
          </v-btn>
          <v-btn
            v-if="action === 'visibility'"
            color="green"
            dark
            @click="handleHideShow(false)"
          >
            {{ $t('common.show', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import api from '@/services/api'
import utilsBooking from '@/services/bookings' 
export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    onRefresh: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    action: null,
    dialog: false,
    selectedEvents: [],
  }),
  watch: {
    action (v) {
      this.selectedEvents = []
      this.dialog = v ? true : false
    },
  },
  methods: {
    padTo2Digits (num) {
      return num.toString().padStart(2, '0')
    },
    formatDate (date) {
      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
        ].join(':')
      )
    },
    handleClose () {
      this.action = null
    },
    prepareEventName (v) {
      return `${this.formatDate(new Date(v.start))} ${v.name}`
    },
    async handleDelete () {
      this.loading = true
      for (const event of this.selectedEvents) {
        const body = null
/*
        const body = this.actionRequired ? {
          action: action,
          sendOwnerMail: sendOwnerMail
        } : null
*/
        await new Promise((resolve) => api.deleteItem('booking', `v1/private/events/`, event, body)
          .then(response => {
            resolve(response)
            if (body && body.action === 'relocate') utilsBooking.handleCheckRelocateReservations('booking', this.workspaceID)
          }))
      }
      this.loading = false
      this.onRefresh()
      this.handleClose()
    },
    async handleHideShow (v) {
      this.loading = true
      for (const event of this.selectedEvents) {
        await new Promise((resolve, reject) => api.updateItem ('booking', `v1/private/events/`, event.concat('/hide'), {Hide: v})
          .then(response => {
            resolve(response)
          })
          .catch (err => {
            alert( 'Error' )
            reject(err)
          }))
      }
      this.loading = false
      this.onRefresh()
      this.handleClose()
    },
    handleSelectAll () {
      this.selectedEvents = this.events.map(item => item.id)
    },
  },
}
</script>

